import React, { useState } from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import cn from 'classnames'
import useMediaQuery from '../../utils/publicApi'
import Button from '../../components/newButton/Button'
// Styles
import styles from './newFooter.module.scss'

const cards = [
  { name: 'visa', src: '/visa.png' },
  { name: 'american', src: '/american.png' },
  { name: 'mastercard', src: '/mastercard.png' },
  { name: 'discover', src: '/discover.png' },
  { name: 'apple-pay', src: '/apple-pay.png' },
  { name: 'google-pay', src: '/google-pay.png' },
  { name: 'amazon-pay', src: '/amazon-pay.png' }
]

const social = [
  { name: 'facebook', src: 'https://www.facebook.com/mawoopets', label: 'Circular Facebook logo in black', className: styles.iconFb },
  { name: 'instagram', src: 'https://www.instagram.com/mawoopets/', label: 'Circular Instagram logo in black', className: styles.iconInstagram },
  { name: 'tiktok', src: 'https://www.tiktok.com/discover/mawoo-pets', label: 'Circular Tiktok logo in black', className: styles.iconTiktok },
  { name: 'pinterest', src: 'https://www.pinterest.com/mawoopets/_created/', label: 'Circular Pinterest logo in black', className: styles.iconPinterest }
]

const routes = {
  puppies: [
    { title: 'Search', slug: '/search' },
    { title: 'Explore all puppies', slug: '/puppies-for-sale' },
    { title: 'Explore by breed', slug: '/puppies-for-sale#breed' },
    { title: 'Explore by state', slug: '/puppies-for-sale#location' },
    { title: 'Explore by category', slug: '/puppies-for-sale#category' }
  ],
  mawooPets: [
    { title: 'Customer Reviews', slug: '/reviews' },
    { title: 'Puppy Travel', slug: '/puppy-travel' },
    { title: 'Blog', slug: '/blog' },
    { title: 'Our Mission', slug: '/mission' },
    { title: 'For Breeders', slug: '/join-as-breeder' }
  ],
  support: [
    { title: 'FAQs', slug: '/faq/customer' },
    { title: 'Contact Us', slug: '/contact' },
    { title: 'Privacy Policy', slug: '/policy' },
    { title: 'Terms & Conditions', slug: '/tos' },
    { title: 'Refund Policy', slug: '/refund-policy' }
  ]
}

const NewFooter = () => {
  const [email, setEmail] = useState('')
  const [hasSubscribed, setHasSubscribed] = useState(false)
  const isMobile = useMediaQuery('(max-width:600px)')

  const { push } = useRouter()

  const onSubmit = (e) => {
    e?.preventDefault()

    if (email && window?._learnq) {
      window._learnq.push([
        'identify',
        {
          $email: email,
          $source: 'Blog subscriber'
        }
      ])

      setHasSubscribed(true)
    }
  }

  return (
    <div className={styles.footerContainer}>
      <div className={styles.logoContainer}>
        <div className={styles.logo} role='img' aria-label='Mawoo Pets horizontal logo in black' />
        <p>Mawoo Pets is a registered entity in USA and Canada.</p>
      </div>

      <div className={styles.badges}>

        <a href='https://www.trustpilot.com/review/www.mawoopets.com?utm_medium=trustbox&utm_source=Carousel' className={styles.sectionTrustpilot}>
          <div className={styles.icon} />
          <div className={styles.starts} />
          <span>Rated <b>4.8</b> out of <b>5</b></span>
        </a>

        <Link
          href='https://www.bbb.org/ca/on/richmond-hill/profile/dog-breeders/friend-a-puppy-ltd-0107-1390922'
          legacyBehavior
        >
          <div className={styles.bbb} role='img' aria-label='BBB logo' />
        </Link>

      </div>

      <div className={styles.linksContainer}>

        <div className={cn(styles.linksColumn, styles.firstColumnHeight)}>
          <p>Puppies</p>
          <div className={styles.linksFind}>

            {routes?.puppies?.map(({ title, slug }) => (
              <Link key={title} href={slug}>
                {title}
              </Link>
            ))}

            {isMobile
              ? (
                <Link href={`${process.env.NEXT_PUBLIC_ROOT_URL}/quiz`}>
                  Puppy Match Quiz
                </Link>
                )
              : null}

          </div>
        </div>

        <div className={cn(styles.linksColumn, styles.secondColumnHeight)}>
          <p>Mawoo Pets</p>
          <div className={styles.links}>

            {
              routes?.mawooPets?.map(({ title, slug }) => (
                <Link href={slug} key={title}>
                  {title}
                </Link>
              ))

            }

          </div>
        </div>

        <div className={cn(styles.linksColumn, styles.thirdColumnHeight)}>
          <p>Support</p>
          <div className={styles.links}>

            {routes?.support?.map(({ title, slug }) => (
              (
                <Link key={title} href={slug}>
                  {title}
                </Link>
              )
            ))}

          </div>
        </div>

      </div>

      {/* News Letter */}
      <div className={styles.newsletter}>
        {hasSubscribed
          ? (
            <>
              <img src='/footer/heart-bubble.svg' alt='heart-bubble' />
              <h3>Thanks for signing up!</h3>
              <p>
                Keep an eye on your email. We’ve got lots of amazing things coming
                your way!{' '}
              </p>
            </>
            )
          : (
            <>
              <h4>Join the pack!</h4>
              <p>
                Sign up and stay up to date with lovely pups, deals, new articles,
                and more!
              </p>
              <form onSubmit={onSubmit}>
                <input
                  type='email'
                  placeholder='Enter email address'
                  autoComplete='off'
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />

                <div className={styles.icon} onClick={onSubmit} />
              </form>
            </>
            )}

        {!isMobile
          ? (
            <div className={styles.buttons}>
              <h4>Find your puppy match!</h4>
              <Button
                theme={['green-solid', 'extra-short']}
                title='Puppy Match Quiz'
                style={{ width: '250px' }}
                onClick={() => {
                  push(`${process.env.NEXT_PUBLIC_ROOT_URL}/quiz`)
                }}
              />
            </div>
            )
          : null}

      </div>

      <div className={styles.lineSeparation} />

      {/* Cards */}
      <div className={styles.payment}>

        {cards?.map((card) => (
          <div
            key={card.name}
            className={styles.card}
            style={{ backgroundImage: `url(${card.src})` }}
            role='img'
            aria-label={`${card.name} logo`}
          />
        ))}

      </div>

      <div className={styles.contact}>

        <a
          className={styles.phoneNumber}
          href='tel:+1-888-488-7203'
          onClick={() => {
            // GTM call
            if (window.dataLayer) { window.dataLayer.push({ event: 'call' }) }
            // UA call
            if (window.ga?.getAll) { window.ga.getAll()[0].send('event', 'call') }
          }}
        >
          <div className={styles.icon} />
          1-888-488-7203
        </a>

        <div className={styles.social}>

          {social?.map((s) => (
            <Link key={s.name} href={s.src} legacyBehavior>
              <div className={s.className} role='img' aria-label={s.label} />
            </Link>
          ))}

        </div>

      </div>
      <div className={styles.copyRight}>
        <p>© 2022 Mawoo Pets LLC. All rights reserved</p>
      </div>
    </div>
  )
}
export default NewFooter
