import { useState, useEffect } from 'react'

const useMediaQuery = (query) => {
  const [match, setMatch] = useState(false)

  useEffect(() => {
    if (
      typeof window === 'undefined' ||
      typeof window.matchMedia === 'undefined'
    ) {
      return
    }
    const mediaQuery = window.matchMedia(query)
    const handler = () => setMatch(mediaQuery.matches)

    handler()

    mediaQuery.addListener(handler)
    return () => mediaQuery.removeListener(handler)
  }, [query])

  return match
}

export default useMediaQuery
